import React, { useEffect, useRef, useState } from 'react';
import { AutoSuggestions } from '../AutoSuggestions';
import { ProductSuggestions } from '../ProductSuggestions/ProductSuggestions';
import { SpecialOffers } from '../SpecialOffers';
import { useTranslation } from 'react-i18next';
import { useAppConfigContext } from '../../hooks/useAppConfigContext';
import { useAutoSuggestions } from '../../hooks/useAutoSuggestions';
import { isEmpty } from '../../utils/utilities';
import { getAutoSuggestOriginalQuery } from '../../utils/autoSuggestResults';
import { CategorySuggestions } from '../CategorySuggestions/CategorySuggestions';
import { useAutoSuggestParamsContext } from '../../hooks/useAutoSuggestParamsContext';
import { ConditionalView } from '../../UI/ConditionalView';
import AutoSuggestParamsActionTypes from '../../reducers/AutoSuggestParamsReducer/AutoSuggestParamsActionTypes';
import styles from './AutoSuggestWrapper.module.scss';

export const AutoSuggestWrapper = (): JSX.Element | null => {
  const [offerActive, setOfferActive] = useState(false);
  const ref = useRef(document.getElementById('search-bar-app'));
  // Get translations for component text.
  const { t } = useTranslation('common');

  // Get the app config state.
  const {
    appConfig
  } = useAppConfigContext();

  const {
    isSuccess,
    data
  } = useAutoSuggestions(appConfig);

  if (!isSuccess) {
    return null;
  }

  if (isEmpty(data)) {
    return null;
  }

  const {
    autoSuggestParams: { autosuggest_modal_open: autosuggestModalOpen },
    setAutoSuggestParams
  } = useAutoSuggestParamsContext();

  useEffect(() => {
    // Offer Domains
    const offerDomains = ['en', 'en-ie'];
    const offerAppliesToDomain = offerDomains.includes(appConfig.current_language);

    // Offer range
    const today = new Date().getTime();
    const offerStart = new Date('Apr 18, 24 05:00:00 GMT').getTime();
    const offerEnd = new Date('Jun 3, 24 22:59:59 GMT').getTime();
    const offerActive = today >= offerStart && today <= offerEnd;

    if (offerAppliesToDomain && offerActive) setOfferActive(true);
  }, [appConfig.current_language]);

  useEffect(() => {
    const checkIfClickedOutside = (e: MouseEvent): void => {
      // If the autosuggest modal is open and the clicked target is not within the modal,
      // then close it.
      let openModal = true;

      if (ref.current !== null && !ref.current.contains(e.target as Element)) {
        openModal = false;
      }
      setAutoSuggestParams({
        type: AutoSuggestParamsActionTypes.SET_AUTOSUGGEST_MODAL_STATE,
        payload: openModal
      });
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [autosuggestModalOpen]);

  const originalQuery = getAutoSuggestOriginalQuery(data);

  if (isEmpty(originalQuery)) {
    return null;
  }

  return (
    <ConditionalView condition={autosuggestModalOpen}>
      <div className={styles.resultWrapper}>
        <h2 className={styles.heading}>
          {t('search.auto_suggest_modal_title')}
          {':'}
          <span> &quot;{originalQuery}&quot;</span>
        </h2>
        <div className={styles.suggestions}>
          <AutoSuggestions/>
          <CategorySuggestions/>
        </div>
        <div className={styles.products}>
          {offerActive && <SpecialOffers/>}
          <ProductSuggestions/>
        </div>
      </div>
    </ConditionalView>
  );
};

export default AutoSuggestWrapper;
